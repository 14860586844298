import React from 'react'
import styled from "@emotion/styled"

const Wrapper = styled.section`
padding-top: 7.5vh;
display: grid;
pointer-events: all;
grid-template-columns: 7fr 5fr;
grid-column-gap: 8vw;
border-bottom: solid 1px rgba(0,0,0,0.05);
padding-bottom: 3vh;
@media (max-width: 599px) {
    display: block;
}
h3 {
    font-weight: 800;
    font-size: .9125rem;
    padding-top: 3vh;
    padding-bottom: 3vh;
}
p {
    font-weight: 500;
    line-height: 1.75em;
    padding-bottom: 18px;
    letter-spacing: .5px;
}
a {
    color: rgba(0,0,0,0.5);
    text-decoration: none;
    transition: all 0.3s ease;
    &:hover {
        color: #000;
        border-bottom: solid 1px rgba(0,0,0,0.33);
    }
}
`

const Content = ({children}) => {
    return (
        <Wrapper>
            {children}
        </Wrapper>
    )
}

export default Content